<template>
  <v-container fluid>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      color="#efb639"
      :opacity="0.9"
    >
    </loading>
    <v-row>
      <v-col cols="12" md="4" class="">
        <v-card flat outlined>
          <v-card-title class="EoleBlueLighten">
            <v-col cols="12" md="10">Liste de vos collègues</v-col>
            <v-col cols="12" md="2"></v-col>
          </v-card-title>
          <v-card-title class="no-padding">
            <v-autocomplete
              dense
              no-data-text="Une erreur est survenue"
              filled
              clearable
              label="Recherche spécifique..."
              v-model="select"
              :search-input.sync="search"
              :items="users"
              :item-text="getItemText"
              item-value="id"
            >
            </v-autocomplete>
          </v-card-title>
          <v-card-text class="no-padding">
            <v-treeview
              dense
              open-all
              :active.sync="active"
              :items="items"
              :load-children="fetchUsers"
              :open.sync="open"
              activatable
              color="EoleBlue"
              transition
              selected-color="EoleYellow"
            >
              <template v-slot:prepend="{ item }">
                <v-avatar
                  class="my-2 mr-2"
                  v-if="item.id && !item.children"
                  size="40"
                  :color="item.avatarUrl === null ? item.color : null"
                  style="border: 1px solid !important"
                >
                  <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                  <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                </v-avatar>
                {{ item.completeName }}
              </template>
            </v-treeview>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
            <p class="text-caption">{{ users.length }} utilisateurs trouvés</p>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="">
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="!selected" align="center" class="text-left">
            <v-col>
              <v-alert outlined type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                >Veuillez selectionner un collaborateur à afficher dans cette fenêtre.
              </v-alert>
            </v-col>
          </v-row>
          <v-card v-else :key="selected.id" class="mx-auto" flat>
            <v-card outlined class="text-center">
              <v-card-text class="profilBanner" style="height: 220px">
                <v-row>
                  <v-col cols="12" md="6" class="text-left"> </v-col>
                  <v-col cols="12" md="6" class="text-right"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="profilBadge">
                <v-avatar v-if="selected.avatarUrl === null" size="128" :color="selected.color">
                  <span class="white--text text-h2">{{ selected.firstname[0] + selected.lastname[0] }}</span>
                </v-avatar>
                <v-avatar v-else size="128">
                  <v-img :src="selected.avatarUrl"> </v-img>
                </v-avatar>
              </v-card-text>

              <v-card-text class="mt-16">
                <v-row>
                  <v-col cols="12" md="6" class="text-left">
                    <h3 class="text-h5">
                      {{ selected.completeName }}
                      <v-chip small label v-if="!!selected.accreditation_code" color="EoleYellow">{{
                        selected.accreditation_code
                      }}</v-chip>
                      <v-chip x-small color="EoleYellow" v-else>En attente de l'information</v-chip>
                    </h3>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-chip small v-if="selected.lastConnection === '0000-00-00 00:00:00'" color="EoleError">
                      <v-icon small class="mr-1"> mdi-close-circle </v-icon>
                      Jamais connecté
                    </v-chip>
                    <v-chip v-else> Connecté {{ selected.lastConnection }} </v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="text-left">
                    <v-row v-if="selected.services.length > 0">
                      <v-col cols="12" md="12" class="text-left">
                        Service :
                        <strong> {{ selected.services.map(service => service.name).join(", ") }}</strong>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n5" v-if="selected.services.length > 0">
                      <v-col cols="12" md="12" class="text-left">
                        Responsable hiérarchique :
                        <strong>
                          {{
                            selected.services
                              .map(service =>
                                service.manager.map(
                                  manager => `${manager.lastname_manager} ${manager.firstname_manager}`,
                                ),
                              )
                              .join(", ")
                          }}
                        </strong>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-chip v-if="selected.available === 0" color="EoleError">
                      <v-icon small class="mr-1"> mdi-close-circle </v-icon>
                      Non disponible
                    </v-chip>
                    <v-chip v-else color="EoleGreen">
                      <v-icon small class="mr-1"> mdi-check </v-icon> Disponible
                    </v-chip>
                  </v-col>
                </v-row>

                <v-spacer class="my-10"></v-spacer>
                <v-row>
                  <v-tooltip v-model="showEmail" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="white--text"
                        rounded
                        color="EoleBlue"
                        :href="`mailto:${selected.email}`"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Contacter par mail</v-btn
                      >
                    </template>
                    <span>{{ selected.email }}</span>
                  </v-tooltip>

                  <v-divider vertical class="mx-2"></v-divider>

                  <v-tooltip v-model="showPhone" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="white--text"
                        rounded
                        color="EoleBlue"
                        :href="`tel:${selected.phoneNumber}`"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Contacter par téléphone</v-btn
                      >
                    </template>
                    <span>{{ selected.phoneNumber }}</span>
                  </v-tooltip>
                </v-row>
              </v-card-text>
              <v-tabs
                class="mt-5"
                centered
                v-model="tab"
                icons-and-text
                style="box-shadow: none !important"
                background-color="EoleBlueLighten"
              >
                <v-tab href="#tab-1">
                  Informations
                  <v-icon>mdi-card-account-details</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-1'">
                  <v-card-text class="pa-5"> Aucune information pour le moment </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
// Import component

import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"

moment.locale("fr")
Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    showPhone: null,
    showEmail: null,
    tab: null,
    fab: false,
    agencyName: null,
    active: [],
    open: [],
    users: [],
    waitTimeout: false,
    pageCount: 0,
    loading: false,
    fetch: false,
    search: null,
    select: null,
  }),

  computed: {
    items() {
      return [
        {
          name: `Mes collègues (${this.users.length})`,
          children: this.users,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      return this.users.find(user => user.id === id)
    },
  },

  async created() {
    this.initialize()
    //this.fetchUsers()
    this.agencyName = localStorage.getItem("agency")
  },
  components: {
    appbar,
    Loading,
  },
  watch: {
    select(item) {
      if (item) {
        this.active = [item]
      }
    },
    search(val) {
      val && val !== this.select
    },
  },
  methods: {
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    getItemText(item) {
      return !!item.accreditation_code
        ? `${item.lastname} ${item.firstname} (${item.accreditation_code})`
        : `${item.lastname} ${item.firstname}`
    },
    async fetchUsers() {
      this.loading = true
      this.waitTimeout = true

      await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("master_account_linked") != null) {
          let masterAccountId = localStorage.getItem("master_account_linked")
          response = await fetch(`${config.apiUri}/users/${masterAccountId}/account`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        } else {
          response = await fetch(`${config.apiUri}/users/${userId}/account`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        }

        const data = await response.json()

        // data.forEach(element => {
        //   this.users.push({
        //     id: element.id,
        //     firstname: element.firstname,
        //     lastname: element.lastname,
        //     lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
        //     statut: element.grade,
        //     email: element.email,
        //     phoneNumber: element.phone_number,
        //     completeName: `${element.lastname} ${element.firstname}`,
        //     agency: element.agency,
        //     color: "#001F47",
        //     initial: element.firstname[0] + element.lastname[0],
        //     accreditation_code: element.accreditation_code,
        //   })
        // })

        // Créez un tableau de promesses pour récupérer les avatars de manière asynchrone
        const avatarPromises = data.map(async element => {
          const avatarUrl = await this.getImg2(element.lastname, element.firstname)
          return {
            element,
            avatarUrl,
          }
        })

        // Utilisez Promise.all pour attendre que toutes les promesses d'avatar soient résolues
        const avatarResults = await Promise.all(avatarPromises)

        avatarResults.forEach(({ element, avatarUrl }) => {
          if (parseInt(element.disabled) === 0) {
            this.users.push({
              id: element.id,
              firstname: element.firstname,
              lastname: element.lastname,
              lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
              statut: element.grade,
              email: element.email,
              phoneNumber: element.phone_number,
              completeName: `${element.lastname} ${element.firstname}`,
              agency: element.agency,
              color: "#001F47",
              initial: element.firstname[0] + element.lastname[0],
              accreditation_code: element.accreditation_code,
              avatarUrl: avatarUrl,
              services: element.services,
              available: parseInt(element.available),
            })
          }
        })

        this.loading = false
        this.fetch = true
        this.waitTimeout = false
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error(
          "Impossible d'obtenir des informations à propos de vos collègues.\nMerci de réessayer ultérieurement.",
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
      }
    },

    getLength() {
      return this.users.length
    },
    initialize() {
      this.users = []
    },

    setup() {
      let searchText = ref("")

      function filteredList() {
        return this.users.filter(data => data.toLowerCase().includes(searchText.value.toLowerCase()))
      }

      return {
        searchText,
        filteredList,
      }
    },
  },
}
</script>
<style lang="scss">
// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

.profilBanner {
  background-image: url("../../../../../src/assets/images/profils/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

// hide button in list
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
